import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Wrapper, MenuItem } from 'react-aria-menubutton';

const LanguageSwitcherStyle = styled.div`
  ${tw`border-b border-solid border-transparent font-montserrat font-semibold text-black text-sm leading-tight no-underline mr-5`}

  > div > span {
    ${tw`flex items-center cursor-pointer outline-none focus:shadow-outline`}
  }
`

const WrapperFlex = styled(Wrapper)`
  display: flex;
  li {
    list-style-type: none;
    margin: 0 10px 0 0;
    &:first-child {
      padding-right: 10px;
      border-right: 1px solid #3a4246;
    }
    a {
      color:#3a4246;
      text-decoration: none;
      font-weight: 500;

    }
  }
`

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);

    this.locales = [
      {
        value: 'en',
        label: 'EN'
      },
      {
        value: 'fr',
        label: 'FR'
      },
    ]
  }

  render() {
    const { urls } = this.props

    return (
      <LanguageSwitcherStyle>
        <WrapperFlex>
          {
            this.locales.map((item) => (
              <li key={item.value}>
                {item.value && urls && urls[item.value.replace('-','_')] ? (
                  <MenuItem value={item.value} text={item.label}>
                    <a 
                      href={urls[item.value.replace('-', '_')]} 
                      alt={item.label} 
                      tabIndex="0" // Makes it focusable with keyboard
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          window.location.href = urls[item.value.replace('-', '_')];
                        }
                      }}
                    >
                      {item.label}
                    </a>
                  </MenuItem>
                ) : null}
              </li>
            ))
          }
        </WrapperFlex>
      </LanguageSwitcherStyle>
    )
  }
}

LanguageSwitcher.propTypes = {
  // URL to change too
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
  }).isRequired,
  // Which locale
  // locale: PropTypes.string.isRequired
}

export default LanguageSwitcher
